<template>
  <div class='top' :style="{background: themesColor}">
    <Breadcrumb ref="bread" />
    <Tenant ref="tenant" />
    <User-info @getThemes="getThemes" />
  </div>
</template>

<script>
import Breadcrumb from './components/Breadcrumb'
import UserInfo from './components/UserInfo'
import Tenant from './components/Tenant'

export default {
  data() {
    return {
      themesColor: '',
    }
  },
  components: {
    Breadcrumb,
    UserInfo,
    Tenant
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getColor()
  },
  methods: {
    getColor() {
      if (localStorage.getItem('themesColor')) {
        this.themesColor = localStorage.getItem('themesColor')
      } else {
        this.themesColor = '#304156'
      }
    },
    getThemes() {
      this.getColor()
      this.$refs.bread.getColor()
      this.$refs.tenant.getColor()
      this.$emit('getThemes')
    },
  },
}
</script>
<style lang="scss" scoped>
.top {
  height: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>