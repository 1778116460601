import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import getters from './getters'
import user from './modules/user'
import dic from './modules/dic'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user, dic
	},
	getters,
	plugins: [createPersistedState({
		storage: window.sessionStorage
	})]
})