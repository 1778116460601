<!-- 按钮弹出框组件 -->
<template>
  <div v-if="show">
    <vxe-modal v-model="show" :title="name" width="800" min-width="800" height="600" min-height="600" showFooter
      esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="150px" style="padding: 20px 100px"
          @submit.native.prevent class="info-form" status-icon :rules="rules" :validate-on-rule-change="false">
          <el-form-item label="主题颜色">
            <el-color-picker v-model="data.themesColor"></el-color-picker>
          </el-form-item>
          <el-form-item label="字体颜色">
            <el-color-picker v-model="data.fontColor"></el-color-picker>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">保存</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      name: '',
      data: {},
      show: false,
      rules: {},
    }
  },
  methods: {
    display() {
      this.show = true
      if (localStorage.getItem('themesColor')) {
        this.$set(this.data, 'themesColor', localStorage.getItem('themesColor'))
      } else {
        this.$set(this.data, 'themesColor', '#304156')
      }
      if (localStorage.getItem('fontColor')) {
        this.$set(this.data, 'fontColor', localStorage.getItem('fontColor'))
      } else {
        this.$set(this.data, 'fontColor', '#FDFDFE')
      }
      this.name = '设置主题'
    },
    save() {
      localStorage.setItem('themesColor', this.data.themesColor)
      localStorage.setItem('fontColor', this.data.fontColor)
      // location.reload()
      this.$emit('getThemes')
      this.show = false
    },
  },
}
</script>