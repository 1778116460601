/* 判断手机号码 */
export function validateMobile(s) {
  return /^1[0-9]{10}$/.test(s)
}

/* 判断六位数字验证码 */
export function validateSixNumber(s) {
  return /^\d{6}$/.test(s)
}

/* 判断车牌号 */

export function validateCarNum(str) {
  const reg = /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/;
  return reg.test(str)
}