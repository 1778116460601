import layout from "@/layout";

export default [
    {
        path: "/",
        redirect: "/login",
        hidden: true,
    },
    {
        path: "/login",
        name: "Login",
        hidden: true,
        meta: {
            flag: "",
            title: "登录",
        },
        component: () => import("@/views/login/index"),
    },
    {
        path: "/dashboard",
        component: layout,
        hidden: true,
        redirect: "/index",
        meta: {
            title: "首页",
        },
        children: [
            {
                path: "index",
                name: "首页",
                meta: {
                    title: "首页",
                },
                component: () => import("@/views/dashboard/index"),
            },
        ],
    },
    {
        path: "/order",
        component: layout,
        name: "订单管理",
        icon: "zhantai",
        meta: {
            title: "订单管理",
        },
        children: [
            {
                path: "sell",
                name: "销售订单",
                icon: "shenqingdan",
                meta: {
                    title: "销售订单",
                },
                component: () => import("@/views/order/sell/index"),
            },
            {
                path: "purchase",
                name: "采购订单",
                icon: "jihua",
                meta: {
                    title: "采购订单",
                },
                component: () => import("@/views/order/purchase/index"),
            }
        ],
    },
    {
        path: "/weigh",
        component: layout,
        name: "磅单管理",
        icon: "guobang",
        meta: {
            title: "磅单管理",
        },
        children: [
            {
                path: "bound",
                name: "过磅单",
                icon: "bangdan",
                meta: {
                    title: "过磅单",
                },
                component: () => import("@/views/weigh/bound/index"),
            },
            {
                path: "abnormal",
                name: "异常处理",
                icon: "yichang",
                meta: {
                    title: "异常处理",
                },
                component: () => import("@/views/weigh/abnormal/index"),
            }
        ],
    },
    {
        path: '/analysis',
        component: layout,
        name: '化验管理',
        icon: 'huayan',
        meta: {
            title: "化验管理",
        },
        children: [
            {
                path: "assay",
                name: "化验单",
                icon: "huayanjilu",
                meta: {
                    title: "化验单",
                },
                component: () => import("@/views/analysis/assay/index"),
            },
            {
                path: "assayRecord",
                name: "化验报表",
                icon: "huayanbaobiao",
                meta: {
                    title: "化验报表",
                },
                component: () => import("@/views/analysis/assayRecord/index"),
            }
        ]
    },
    {
        path: '/wareHouse',
        component: layout,
        name: '仓储管理',
        icon: 'cangchu',
        meta: {
            title: "仓储管理",
        },
        children: [
            {
                path: "spaceName",
                name: "仓库管理",
                icon: "cangku",
                meta: {
                    title: "仓库管理",
                },
                component: () => import("@/views/wareHouse/spaceName/index"),
            },
            {
                path: "location",
                name: "货位管理",
                icon: "huowei",
                meta: {
                    title: "货位管理",
                },
                component: () => import("@/views/wareHouse/location/index"),
            },
            {
                path: "stock",
                name: "库存单",
                icon: "kucun",
                meta: {
                    title: "库存单",
                },
                component: () => import("@/views/wareHouse/stock/index"),
            },
            {
                path: "stockOrder",
                name: "出入库单",
                icon: "churuku",
                meta: {
                    title: "出入库单",
                },
                component: () => import("@/views/wareHouse/stockOrder/index"),
            },
            {
                path: "stockPan",
                name: "盘库单",
                icon: "panku",
                meta: {
                    title: "盘库单",
                },
                component: () => import("@/views/wareHouse/stockPan/index"),
            },
        ]
    },
    {
        path: "/report",
        component: layout,
        name: "报表管理",
        icon: "jihua",
        meta: {
            title: "报表管理"
        },
        children: [
            {
                path: "purchaseWeighReport",
                name: "采购过磅日报表",
                icon: "shenqingdan",
                meta: {
                    title: "采购过磅日报表",
                },
                component: () => import("@/views/report/purchaseWeighReport/index"),
            },
            {
                path: "sellWeighReport",
                name: "销售过磅日报表",
                icon: "shenqingdan",
                meta: {
                    title: "销售过磅日报表",
                },
                component: () => import("@/views/report/sellWeighReport/index"),
            }
        ]
    },
    {
        path: "/system",
        component: layout,
        name: "系统设置",
        icon: "shezhi",
        meta: {
            title: "系统设置",
        },
        children: [
            {
                path: "company",
                name: "公司管理",
                icon: "gongsi",
                meta: {
                    title: "公司管理",
                },
                component: () => import("@/views/system/company/index"),
            },
            {
                path: "customer",
                name: "客户管理",
                icon: "kehu",
                meta: {
                    title: "客户管理",
                },
                component: () => import("@/views/system/customer/index"),
            },
            {
                path: "supplier",
                name: "供应商管理",
                icon: "gongyingshang",
                meta: {
                    title: "供应商管理",
                },
                component: () => import("@/views/system/supplier/index"),
            },
            {
                path: "vehicle",
                name: "车辆管理",
                icon: "cheliang",
                meta: {
                    title: "车辆管理",
                },
                component: () => import("@/views/system/vehicle/index"),
            },
            {
                path: "product",
                name: "产品管理",
                icon: "chanpin",
                meta: {
                    title: "产品管理",
                },
                component: () => import("@/views/system/product/index"),
            },
            {
                path: "role",
                name: "角色管理",
                icon: "caidan",
                meta: {
                    title: "角色管理",
                },
                component: () => import("@/views/system/role/index"),
            },
            {
                path: "user",
                name: "用户管理",
                icon: "yonghu",
                meta: {
                    title: "用户管理",
                },
                component: () => import("@/views/system/user/index"),
            },
            {
                path: "printer",
                name: "打印机管理",
                icon: "dayin",
                meta: {
                    title: "打印机管理",
                },
                component: () => import("@/views/system/printer/index"),
            }
        ],
    },
];
