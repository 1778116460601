// import axios from '@/http'
// import store from '@/store'

export default {
  state: {
    isCollapse: false,
    isMenu: false,
  },
  actions: {},
  mutations: {
    set_isCollapse: (state, data) => {
      state.isCollapse = data
    },
    set_isMenu: (state, data) => {
      state.isMenu = data
    }
  }
}