<template>
  <div class="logo" @click="$router.push({ name: '首页' })">
    <img src="@/assets/ml-logo.png" alt="" v-show="isCollapse" class="small" />
    <img src="@/assets/ml-logo.png" alt="" v-show="!isCollapse" class="big" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  components: {},
  computed: {
    ...mapGetters(['isCollapse']),
  },
  watch: {},
  created() { },
  mounted() { },
  methods: {},
}
</script>
<style scoped>
.logo {
  text-align: center;
}

.logo .big {
  width: 60px;
  margin: 15px 0 0 0;
}

.logo .small {
  width: 60px;
  margin: 15px 0 0 0;
}
</style>
