import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI)
import router from './router'
import store from './store'
import '@/styles/index.scss'
import '@/http'
import '@/components'
import XEUtils from 'xe-utils'
import VXEUtils from 'vxe-utils'
import VXETable from 'vxe-table'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)
Vue.use(VXEUtils, XEUtils)
VXETable.use(VXETablePluginExportXLSX)
import Clipboard from 'clipboard'
Vue.prototype.Clipboard = Clipboard;
import * as validate from '@/utils/validate'
Vue.prototype.$validate = validate
import VueParticles from 'vue-particles'
Vue.use(VueParticles)
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')