<template>
  <div class="breadcrumb" ref="UI">
    <i id="guide-collapse" class="collapse-icon" :style="{color: fontColor}"
      :class="isCollapse?'el-icon-s-unfold':'el-icon-s-fold'" @click="changeCollapse"></i>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <transition-group name="breadcrumb">
        <template v-if="$route.name !== '首页'">
          <el-breadcrumb-item :to="{ name: '首页' }" key="首页" style="font-size: 12px">首页
          </el-breadcrumb-item>
          <el-breadcrumb-item v-for="item in route" :key="item.path" style="font-size: 12px">
            {{item.meta.title}}
          </el-breadcrumb-item>
        </template>
        <template v-if="$route.name === '首页'">
          <el-breadcrumb-item :to="{ name: '首页' }" key="首页" style="font-size: 12px">首页
          </el-breadcrumb-item>
        </template>
      </transition-group>
    </el-breadcrumb>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      fontColor: '#fdfdfe',
    }
  },
  components: {},
  computed: {
    ...mapGetters(['isCollapse']),
    route() {
      return this.$route.matched
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.getColor()
  },
  methods: {
    changeCollapse() {
      this.$store.commit('set_isCollapse', !this.isCollapse)
    },
    getColor() {
      if (localStorage.getItem('fontColor')) {
        this.fontColor = localStorage.getItem('fontColor')
      } else {
        this.fontColor = '#fdfdfe'
      }
      this.$refs.UI.style.setProperty('--fontColor', this.fontColor)
    },
  },
}
</script>
<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  justify-content: center;
  align-items: center;

  .collapse-icon {
    font-size: 26px;
    margin-right: 10px;
  }
  .collapse-icon:hover {
    color: #F1A131;
    cursor: pointer;
  }
}
</style>
<style vars="{ fontColor }">
.breadcrumb .el-breadcrumb .el-breadcrumb__item .el-breadcrumb__inner {
  color: var(--fontColor) !important;
}
</style>