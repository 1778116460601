<template>
  <i :class="`icon iconfont icon-${name}`"></i>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: ['name'],
  components: {},
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>
<style scoped>
</style>