<template>
  <div class='sidebar' :style="{background: themesColor}">
    <el-scrollbar style="height: calc(100% - 50px);">
      <Logo />
      <Menu ref="menu" />
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './components/Logo'
import Menu from './components/Menu'

export default {
  data() {
    return {
      themesColor: '',
    }
  },
  components: {
    Menu, Logo
  },
  computed: {
    ...mapGetters(['isCollapse']),
  },
  watch: {},
  created() {},
  mounted() {
    this.getColor()
  },
  methods: {
    getColor() {
      if (localStorage.getItem('themesColor')) {
        this.themesColor = localStorage.getItem('themesColor')
      } else {
        this.themesColor = '#304156'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.sidebar {
  height: 100%;
}
</style>