import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import {
  Message
} from 'element-ui'

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '/api' : '/api'
axios.defaults.validateStatus = status => {
  return status >= 200 && status < 250
}
axios.defaults.timeout = 600000;
axios.interceptors.request.use(item => { //拦截请求
  item.headers.token = localStorage.getItem('token')
  item.headers.tenantId = localStorage.getItem('tenantId')
  return item
}, error => {
  Message.error('请检查网络连接！')
  return Promise.reject(error)
})

axios.interceptors.response.use(item => { //拦截响应	
  if (item.data.code === '0000') {
    return item
  } else if (item.data.code === '2010') {
    if (router.app._route.name !== 'Login') {
      router.replace({
        name: 'Login'
      })
    }
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('tenantName')
    localStorage.removeItem('tenantId')
    if (item.data.message)
      Message.error(item.data.message)
    else if (item.data.msg)
      Message.error(item.data.msg)
    else
      Message.error('网络错误！')
    return Promise.reject()
  } else {
    if (item.data.message)
      Message.error(item.data.message)
    else if (item.data.msg)
      Message.error(item.data.msg)
    else
      Message.error('网络错误！')
    return Promise.reject()
  }
}, error => {
  const status = error.response.status
  switch (status) {
    case 403:
      if (router.app._route.name !== 'Login') {
        router.replace({
          name: 'Login'
        })
      }
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('tenantName')
      localStorage.removeItem('tenantId')
      break
    default:
      break
  }
  const msg = error.response.data.msg ? error.response.data.msg : error.response.data.message ? error.response.data.message : '网络错误！';
  Message.error(msg);
  return Promise.reject(error)
})

Vue.prototype.$axios = axios

export default axios