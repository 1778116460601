<template>
  <div class="layout">
    <el-container class="container">
      <Sidebar ref="side" />
      <el-container>
        <el-header class="header">
          <div>
            <Top @getThemes="getThemes" />
            <!--            <Tags @tags="tags" />-->
          </div>
        </el-header>
        <transition name="fade-transform" mode="out-in">
          <!--          <keep-alive :include="tagsList">-->
          <router-view :key="$route.path"></router-view>
          <!--          </keep-alive>-->
        </transition>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar'
import Top from './components/Top'
// import Tags from './components/Tags'

export default {
  data() {
    return {
      tagsList: [],
    }
  },
  components: {
    Sidebar,
    Top,
    // Tags,
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    // tags(msg) {
    //   let arr = []
    //   let pathNameList = []
    //   for (let i = 0, len = msg.length; i < len; i++) {
    //     msg[i].name && arr.push(msg[i].name)
    //     msg[i].pathName && pathNameList.push(msg[i].pathName)
    //   }
    //   this.tagsList = arr
    //   setTimeout(() => {
    //     sessionStorage.setItem('pathNameList', JSON.stringify(pathNameList))
    //   }, 1000)
    // },
    getThemes() {
      this.$refs.side.getColor()
      this.$refs.side.$refs.menu.getColor()
    },
  },
}
</script>
<style lang="scss" scoped>
.layout {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 1400px;
  background: #f5f7f9;

  .container {
    height: 100%;
    .header {
      padding: 0;
      height: 60px !important;
    }
  }
}
</style>