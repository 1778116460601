<template>
  <div class="tenantInfo">
    <div class="info">
      <el-dropdown @command="handleCommand">
        <span style="cursor: pointer;">
          <template v-if="!tenant.name">
            <span class="name" :style="{color: fontColor}" style="font-weight: bold"> 请创建企业 </span>
            <span :style="{color: fontColor}" class="el-icon-arrow-down"></span>
          </template>
          <template v-else>
            <span class="name" :style="{color: fontColor}" style="font-weight: bold"> {{ tenant.name }} </span>
            <span :style="{color: fontColor}" class="el-icon-arrow-down"></span>
          </template>
        </span>
        <el-dropdown-menu slot="dropdown" style="text-align: center">
          <template v-for="(item, index) in tenantList">
            <el-dropdown-item :command="item.id" :key="index">
              <span :class="item.id === tenant.id ? 'active-name' : ''">{{ item.name }}</span>
            </el-dropdown-item>
          </template>
          <el-dropdown-item command="createTenant" style="border-top: 1px solid #DADCE0; padding-top: 5px">
            <el-button type="text" class="el-icon-plus"> 创建企业 </el-button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-if="show">
      <vxe-modal v-model="show" :title="tenantName" width="400" min-width="400" height="210" min-height="210" showFooter
        esc-closable mask-closable resize>
        <template v-slot>
          <el-form ref="form" size="small" :model="tenantData" label-width="150px" label-position="top" :rules="rules">
            <el-form-item prop="tenantName" label="企业名称">
              <el-input v-model.trim="tenantData.tenantName" placeholder="请输入企业名称" type="text"></el-input>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:footer>
          <div style="text-align: center">
            <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">创建</vxe-button>
          </div>
        </template>
      </vxe-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      fontColor: '',
      tenantInfo: {},
      tenantList: [],
      tenant: {},
      loading: false,
      tenantName: '',
      tenantData: {},
      show: false,
      rules: {
        tenantName: {
          required: true,
          message: '请输入企业简称',
          trigger: 'blur'
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userRole']),
  },
  created() {
    this.getColor()
    this.getTenantList(false)
  },
  methods: {
    handleCommand(command) {
      if (command === 'createTenant') {
        this.display()
      } else {
        this.tenantList.forEach(tenant => {
          if (tenant.id === command && this.tenant.id !== command) {
            this.$confirm('切换企业会跳转到首页，确定切换企业吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.tenant = tenant
                localStorage.setItem('tenantName', tenant.name)
                localStorage.setItem('tenantId', tenant.id)
                this.$store.commit('set_isMenu', true)
                this.$router.push({ name: '首页' })
                  
              })
              .catch(() => {})
          }
        })
      }
    },
    getColor() {
      if (localStorage.getItem('fontColor')) {
        this.fontColor = localStorage.getItem('fontColor')
      } else {
        this.fontColor = '#fdfdfe'
      }
    },
    getTenantList(isCloseAll) {
      this.tenantList = []
      this.tenant = {}
      let isTenant = false
      this.$axios.get('/auth/user/queryTenantList').then(res => {
        if (res) {
          this.tenantList = res.data.data
          if (this.tenantList.length > 0) {
            if (localStorage.getItem('tenantName')) {
              this.tenantList.forEach(tenant => {
                if (tenant.name === localStorage.getItem('tenantName')) {
                  this.tenant = tenant
                  isTenant = true
                  if (isCloseAll) {
                    this.$router.push({ name: '首页' })
                  }
                }
              })
              if (!isTenant) {
                this.tenant = this.tenantList[0]
                localStorage.setItem('tenantName', this.tenant.name)
                localStorage.setItem('tenantId', this.tenant.id)
              }
            } else {
              this.tenant = this.tenantList[0]
              localStorage.setItem('tenantName', this.tenant.name)
              localStorage.setItem('tenantId', this.tenant.id)
            }
            this.$store.commit('set_isMenu', true)
            if (this.tenant.name !== localStorage.getItem('tenantName')) {
              this.$router.push({ name: '首页' })
            }
          } else {
            this.tenant = {}
            this.$store.commit('set_isMenu', false)
            this.$router.push({ name: '首页' })
          }
          this.$store.dispatch('getUserRole')
        }
      })
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
            const params = {
                tenantName: this.tenantData.tenantName
            }
          this.$axios.post(`/auth/tenant/create`, params)
            .then(res => {
              if (res) {
                this.show = false
                localStorage.setItem('tenantName', this.tenantData.tenantName)
                this.getTenantList(true)
              }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    display() {
      this.show = true
      this.tenantName = '创建企业'
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      this.tenantData = {}
    }
  }
}
</script>
<style lang="scss" scoped>
.tenantInfo {
  display: flex;
  justify-content: center;
  align-items: center;

  .info {
    display: flex;
    justify-content: center;
    align-items: center;

    .name {
      margin-right: 5px;
      font-size: 14px;
    }
  }
}

.modal-detail {
  ::v-deep .vxe-modal--content {
    white-space: normal !important;
  }
}

.active-name {
  color: #F1A131;
  font-weight: bold;
}
</style>