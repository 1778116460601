import axios from "@/http";
import XEUtils from 'xe-utils'

export default {
  state: {
    productType: [],
    product: [],
    company: [],
    customer: [],
    supplier: [],
    spaceName: [],
    userRole: []
  },
  actions: {
    getProductType({ commit }) {
      let data = [];
      axios.get("/order/productType/list").then((res) => {
        if (res) {
          data = res.data.data;
          commit("set_product_type", data);
        }
      });
    },
    getProduct({ commit }) {
      let data = [];
      axios.get("/order/product/list").then((res) => {
        if (res) {
          data = res.data.data;
          commit("set_product", data);
        }
      });
    },
    getCompany({ commit }) {
      let data = [];
      axios.get("/order/company/list").then((res) => {
        if (res) {
          data = res.data.data;
          commit("set_company", data);
        }
      });
    },
    getCustomer({ commit }) {
      let data = [];
      axios.get("/order/customer/list").then((res) => {
        if (res) {
          data = res.data.data;
          commit("set_customer", data);
        }
      });
    },
    getSupplier({ commit }) {
      let data = [];
      axios.get("/order/supplier/list").then((res) => {
        if (res) {
          data = res.data.data;
          commit("set_supplier", data);
        }
      });
    },
    getSpaceName({ commit }) {
      let data = [];
      const params = {
        params: {
          pageNum: 1,
          pageSize: 10000
        }
      }
      axios.get("/order/spaceName/queryList", params).then((res) => {
        if (res) {
          if (res.data.data.list) data = res.data.data.list
          commit("set_space_name", data);
        }
      });
    },
    getLocation({ commit }) {
      let data = [];
      const params = {
        params: {
          pageNum: 1,
          pageSize: 10000
        }
      }
      axios.get("/order/Location/queryList", params).then((res) => {
        if (res) {
          if (res.data.data.list) data = res.data.data.list
          commit("set_location", data);
        }
      });
    },
    getUserRole({ commit }) {
      let data = [];
      axios.get("/auth/user/queryMemberInfo").then((res) => {
        if (res) {
          data = res.data.data;
          let list = []
          if (data.list && data.list.length > 0) {
            list = XEUtils.map(data.list, item => item.code)
          } else {
            list = []
          }
          commit("set_user_role", list);
        }
      });
    },
    getAssayProduct({ commit }, id) {
      axios.get(`/order/assay/item/product/${id}`).then(res => {
        if (res) {
          const data = res.data.data
          commit('set_assay_product', data)
        }
      })
    },
    getAssayProductType({ commit }, id) {
      axios.get(`/order/assay/item/type/${id}`).then(res => {
        if (res) {
          const data = res.data.data
          commit('set_assay_product_type', data)
        }
      })
    },
  },
  mutations: {
    set_product_type: (state, data) => {
      state.productType = data;
    },
    set_product: (state, data) => {
      state.product = data;
    },
    set_company: (state, data) => {
      state.company = data;
    },
    set_customer: (state, data) => {
      state.customer = data;
    },
    set_supplier: (state, data) => {
      state.supplier = data;
    },
    set_space_name: (state, data) => {
      state.spaceName = data;
    },
    set_location: (state, data) => {
      state.location = data;
    },
    set_user_role: (state, data) => {
      state.userRole = data;
    },
    set_assay_product: (state, data) => {
      state.assayProduct = data;
    },
    set_assay_product_type: (state, data) => {
      state.assayProductType = data;
    },
  },
};
