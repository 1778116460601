export default {
	isCollapse: state => state.user.isCollapse,
	isMenu: state => state.user.isMenu,
	productType: state => state.dic.productType,
	product: state => state.dic.product,
	company: state => state.dic.company,
	customer: state => state.dic.customer,
	supplier: state => state.dic.supplier,
	spaceName: state => state.dic.spaceName,
	location: state => state.dic.location,
	userRole: state => state.dic.userRole,
	assayProduct: state => state.dic.assayProduct,
	assayProductType: state => state.dic.assayProductType,
}
