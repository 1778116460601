<template>
  <div class="userInfo">
    <div class="info">
      <el-dropdown @command="handleCommand">
        <div>
          <el-avatar class="avatar" shape="square">{{avatarName}}</el-avatar>
          <span class="name" :style="{color: fontColor}">{{userInfo.name}}</span>
          <span :style="{color: fontColor}" class="el-icon-caret-bottom"></span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="themes">设置主题</el-dropdown-item>
          <!-- <el-dropdown-item command="updatePhone">修改手机号</el-dropdown-item> -->
          <!-- <el-dropdown-item command="platform">切换站台</el-dropdown-item> -->
          <el-dropdown-item command="signOut">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <themes ref="themes" @getThemes="getThemes" />
  </div>
</template>

<script>
import themes from './themes'

export default {
  data() {
    return {
      fontColor: '',
      userInfo: {},
      loading: false,
      avatarName: '',
    }
  },
  components: { themes },
  mounted() {
    this.getColor()
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.avatarName = this.userInfo.name.slice(-2)
  },
  methods: {
    handleCommand(command) {
      if (command === 'themes') {
        this.$refs.themes.display()
      } else if (command === 'signOut') {
        this.$confirm('确定退出吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$router.push('/')
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            localStorage.removeItem('tenantId')
            localStorage.removeItem('tenantName')
          })
          .catch(() => {})
      }
    },
    getColor() {
      if (localStorage.getItem('fontColor')) {
        this.fontColor = localStorage.getItem('fontColor')
      } else {
        this.fontColor = '#fdfdfe'
      }
    },
    getThemes() {
      this.getColor()
      this.$emit('getThemes')
    }
  },
}
</script>
<style lang="scss" scoped>
.userInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  line-height: 60px;

  .info {
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar {
      vertical-align: middle;
      margin-left: 10px;
    }

    .name {
      margin-left: 10px;
      font-size: 14px;
    }
  }
}
</style>