<template>
  <div class="menu">
    <el-menu :id="hasGuide ? 'guide-menu' : ''" :style="{width: !isCollapse ? '200px' : '64px'}"
      :collapse-transition="false" :collapse="isCollapse" :default-active="$route.name" :background-color="themesColor"
      :text-color="fontColor" active-text-color="#F1A131" unique-opened menu-trigger="click">
      <template v-for="item in routes">
        <el-submenu :index="item.name" v-if="!item.hidden" :key="item.path">
          <template slot="title">
            <Icon :name="item.icon" class="menu-icon" :style="{color: fontColor}" />
            <span>{{ item.meta.title }}</span>
          </template>
          <template v-for="_item in item.children">
            <el-menu-item :index="_item.name" @click="go(_item)" :key="_item.path">
              <template slot="title">
                <Icon :name="_item.icon" class="menu-icon" />
                <span>{{ _item.meta.title }}</span>
              </template>
            </el-menu-item>
          </template>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import variables from '@/styles/variables.scss'

export default {
  data() {
    return {
      themesColor: '',
      fontColor: ''
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['isCollapse', 'isMenu']),
    variables() {
      return variables
    },
    routes() {
      return this.$router.options.routes
    },
    hasGuide() {
      return this.routes.some(item => {
        return !item.hidden
      })
    }
  },
  created() {},
  mounted() {
    this.getColor()
  },
  methods: {
    go(item) {
      if (this.$route.name != item.name) {
        this.$router.push({
          name: item.name
        })
      }
    },
    getColor() {
      if (localStorage.getItem('themesColor')) {
        this.themesColor = localStorage.getItem('themesColor')
      } else {
        this.themesColor = '#304156'
      }
      if (localStorage.getItem('fontColor')) {
        this.fontColor = localStorage.getItem('fontColor')
      } else {
        this.fontColor = '#fdfdfe'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.menu {
  ::v-deep .el-menu {
    border-right: 0;
  }

  .menu-icon {
    font-size: 18px;
    margin-right: 5px;
    position: relative;
    bottom: 2px;
  }
}
</style>

<style vars="{ fontColor }">
.el-menu--vertical .el-menu .el-menu-item .menu-icon {
  font-size: 18px;
  margin-right: 5px;
  color: var(--fontColor) !important;
}
.menu .el-menu-item i {
  color: var(--fontColor) !important;
}
</style>
